@charset "utf-8";
@font-face {
  font-family: 'twentytwelve_twentytwelveslab';
  src: url('../../fonts/2012/twentytwelveslab-bold-webfont.eot');
  src: url('../../fonts/2012/twentytwelveslab-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/2012/twentytwelveslab-bold-webfont.woff') format('woff'), url('../../fonts/2012/twentytwelveslab-bold-webfont.ttf') format('truetype'), url('../../fonts/2012/twentytwelveslab-bold-webfont.svg#twentytwelve_twentytwelveslab') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HittheRoadRegular';
  src: url('../../fonts/hittheroad/hitroad.eot');
  src: url('../../fonts/hittheroad/hitroad.eot') format('embedded-opentype'), url('../../fonts/hittheroad/hitroad.woff') format('woff'), url('../../fonts/hittheroad/hitroad.ttf') format('truetype'), url('../../fonts/hittheroad/hitroad.svg#HittheRoadRegular') format('svg');
}
/*
@import 'respond-framework-v2/combined.less';
@import 'menu.less';*/
/* CSS Document */
* {
  margin: 0;
  padding: 0;
}
a img {
  border: none;
}
/* must declare 0 margins on everything, also for main layout components use padding, not
vertical margins (top and bottom) to add spacing, else those margins get added to total height
and your footer gets pushed down a bit more, creating vertical scroll bars in the browser */
html,
body {
  height: 100%;
  width: 100%;
  background-color: #FFF;
  position: relative;
  color: #666;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 18px;
}
.wrap {
  min-height: 100%;
  width: 100%;
}
.sky {
  width: 100%;
  height: 700px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #0168b5;
  /* Old browsers */
  background: -moz-linear-gradient(top, #0168b5 0%, #39acff 50%, #ffffff 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0168b5), color-stop(50%, #39acff), color-stop(100%, #ffffff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #0168b5 0%, #39acff 50%, #ffffff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #0168b5 0%, #39acff 50%, #ffffff 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #0168b5 0%, #39acff 50%, #ffffff 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #0168b5 0%, #39acff 50%, #ffffff 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0168b5', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
}
.main {
  padding-bottom: 425px;
  /* must be same height as the footer */
  width: 100%;
  z-index: 999;
}
.main2 {
  position: relative;
}
/*Opera Fix*/
body:before {
  /* thanks to Maleika (Kohoutec)*/
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px;
  /* thank you Erik J - negate effect of float*/
}
.footer {
  margin-top: -425px;
  /* negative value of footer height */
  height: 222px;
  padding-top: 203px;
  width: 100%;
  clear: both;
  background-image: url('/images/footerBG.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #318c03;
  position: relative;
}
.container {
  width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  margin-top: -3px;
}
.container {
  border-top: 3px solid #00275b;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#999999), to(transparent));
  background-image: -webkit-linear-gradient(#999999, transparent);
  background-image: -moz-linear-gradient(#999999, transparent), -moz-linear-gradient(#999999, transparent);
  background-image: -o-linear-gradient(#999999, transparent), -o-linear-gradient(#999999, transparent);
  background-image: linear-gradient(#999999, transparent), linear-gradient(#999999, transparent);
  -moz-background-size: 3px 100%;
  background-size: 3px 50%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
}
.header {
  width: 980px;
  height: 196px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-repeat: repeat-x;
}
.logo {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 288px;
  height: 145px;
}
h1.strapline {
  font-family: 'HittheRoadRegular';
  position: absolute;
  top: 25px;
  left: 300px;
  width: 410px;
  height: 75px;
  display: block;
  padding-left: 5px;
  color: #fff;
  font-style: italic;
  font-size: 40px;
  line-height: normal;
  font-weight: normal;
  letter-spacing: -0.5px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
  -moz-text-shadow: 2px 2px 3px #000;
  -webkit-text-shadow: 2px 2px 3px #000;
  filter: progid:DXImageTransform.Microsoft.Shadow(strength=4, color=#000000, direction=135);
}
.contacts {
  position: absolute;
  top: 0px;
  right: 15px;
  width: 210px;
  height: 75px;
  background-image: url('/images/phone-icon.jpg');
  background-repeat: no-repeat;
  background-position: left top;
  color: #fff;
  padding-left: 35px;
  text-align: right;
}
.tel {
  font-family: 'HittheRoadRegular';
  font-size: 30px;
  font-weight: normal;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
  -moz-text-shadow: 2px 2px 3px #000;
  -webkit-text-shadow: 2px 2px 3px #000;
  filter: progid:DXImageTransform.Microsoft.Shadow(strength=4, color=#000000, direction=135);
  margin-top: 32px;
  margin-bottom: 10px;
}
.email a {
  color: #8fe2ff;
}
.social {
  width: 103px;
  height: 28px;
  overflow: hidden;
  float: right;
  margin-top: 10px;
}
.social a {
  display: block;
  width: 28px;
  height: 0px;
  padding-top: 27px;
  margin-left: 6px;
  float: left;
  overflow: hidden;
}
#facebook {
  background-image: url('/images/facebook1.jpg');
  background-repeat: no-repeat;
}
#facebook:hover {
  background-position: left -27px;
}
#google {
  background-image: url('/images/google1.jpg');
  background-repeat: no-repeat;
}
#google:hover {
  background-position: left -27px;
}
#twitter {
  background-image: url('/images/twitter1.jpg');
  background-repeat: no-repeat;
}
#twitter:hover {
  background-position: left -27px;
}
#youtube {
  background-image: url('/images/youtube1.jpg');
  background-repeat: no-repeat;
}
#youtube:hover {
  background-position: left -27px;
}
.navbar {
  height: 45px;
  width: 100%;
  background-color: #00275b;
  position: absolute;
  left: 0px;
  bottom: 0px;
  font-family: 'HittheRoadRegular';
  font-size: 14px;
  line-height: 16px;
}
.navbar ul {
  list-style: none;
  padding-top: 3px;
}
.navbar ul li {
  float: left;
  display: block;
  text-align: center;
}
.navbar ul li:not(:last-child) {
  background-image: url('/images/divides.jpg');
  background-repeat: no-repeat;
  background-position: right top;
}
.navbar a {
  color: #fff;
  height: 35px;
  padding-left: 20px;
  padding-right: 22px;
  padding-top: 4px;
  display: block;
  text-decoration: none;
}
.navbar a:hover {
  color: #b5db0f;
}
body.home li#home a,
body.range li#range a,
body.rates li#rates a,
body.extras li#extras a,
body.festivals li#festivals a,
body.terms li#terms a,
body.specials li#specials a,
body.contact li#contact a {
  color: #b5db0f;
}
.getaquote {
  position: absolute;
  right: 5px;
  bottom: -16px;
  width: 158px;
  height: 83px;
  z-index: 500;
}
.slideshow {
  width: 100%;
  height: 250px;
  background-color: #ccc;
  margin-top: 15px;
  margin-bottom: 15px;
}
.column {
  width: 307px;
  height: 450px;
  float: left;
  color: #fff;
}
.column div {
  padding: 15px;
  padding-top: 5px;
  height: 240px;
  position: relative;
}
.column h2 {
  font-family: 'HittheRoadRegular';
  font-weight: normal;
  font-size: 26px;
  letter-spacing: -0.5px;
  line-height: 30px;
  margin-bottom: 10px;
}
.greenbutton {
  display: inline-block;
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: #0b7f3d;
  border: solid 2px #075428;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 23px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url('/images/green-arrow.jpg');
  background-repeat: no-repeat;
  background-position: right center;
}
.greenbutton:hover {
  border: 2px solid #fff;
}
#col1 {
  background-color: #1497c4;
}
#col2 {
  background-color: #ff8d1a;
  margin-left: 14px;
  margin-right: 14px;
}
#col3 {
  background-color: #70a539;
}
.left {
  float: left;
  width: 287px;
  padding: 10px;
}
.left h4 {
  font-family: 'twentytwelve_twentytwelveslab';
  color: #000;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 7px;
}
.left a,
.col2 a {
  color: #6443b5;
}
.left a:hover,
.col2 a:hover {
  color: #86a702;
}
.offers,
.extra {
  background-image: url('/images/arrow.jpg');
  background-repeat: no-repeat;
  background-position: right 35px;
  display: block;
  width: 287px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.top {
  padding-bottom: 15px;
  border: solid 1px #ddd;
  background-color: #eee;
}
.top h4 {
  display: block;
  background-color: #6342b4;
  color: #fff;
  font-size: 22px;
  width: 263px;
  padding-top: 12px;
  padding-right: 11px;
  padding-bottom: 12px;
  padding-left: 12px;
  margin-bottom: 5px;
}
.top span {
  padding: 5px;
  display: block;
}
.offers {
  border-bottom: dotted #ccc 1px;
}
.euro {
  background-image: url('/images/flag.jpg');
  background-repeat: no-repeat;
  background-position: right 12px;
  display: block;
  width: 287px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.euro h4 {
  line-height: 24px;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  font-weight: normal;
  font-family: 'twentytwelve_twentytwelveslab';
}
.right {
  float: right;
  width: 630px;
}
.right h1,
.left h1,
.right h3 {
  font-family: 'twentytwelve_twentytwelveslab';
  color: #000;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: normal;
  line-height: normal;
  text-transform: uppercase;
}
.right h1,
.left h1 {
  font-size: 28px;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
ul.blocks {
  list-style: none;
  display: block;
  width: 100%;
  height: 146px;
  padding-bottom: 15px;
  border-bottom: dotted #ccc 1px;
}
ul.blocks li {
  display: block;
  float: left;
  width: 146px;
  height: 146px;
  background-color: #ccc;
  position: relative;
}
ul.blocks li:not(:last-child) {
  margin-right: 15px;
}
ul.blocks li div {
  display: block;
  width: 136px;
  background-image: url('../../img/caption-bg.png');
  background-repeat: repeat;
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 5px;
}
span.head {
  font-size: 18px;
  font-weight: normal;
  font-family: 'HittheRoadRegular';
  display: inline;
}
ul.blocks li a {
  color: #b5db0f;
  text-decoration: none;
}
ul.blocks li a:hover {
  color: #b5db0f;
  text-decoration: underline;
}
#festivalhire {
  background-image: url('/images/festival-hire.jpg');
  background-repeat: no-repeat;
}
#corporatehire {
  background-image: url('/images/corporate-hire.jpg');
  background-repeat: no-repeat;
}
#whocandrive {
  background-image: url('/images/motorhome-driving.jpg');
  background-repeat: no-repeat;
}
#topdestinations {
  background-image: url('/images/directions.jpg');
  background-repeat: no-repeat;
}
#campers {
  background-image: url('/images/new-optional.jpg');
  background-repeat: no-repeat;
}
.col1 {
  float: left;
  width: 291px;
  border-right: dotted #ccc 1px;
  padding: 12px;
}
.col2 {
  float: right;
  width: 288px;
  padding: 12px;
}
.col2 ul {
  margin-left: 15px;
  color: #6443b5;
  font-size: 16px;
}
.col2 ul li {
  margin-bottom: 10px;
}
.col2 ul li span {
  color: #666;
  font-size: 12px;
}
.clear {
  width: 100%;
  height: 1px;
  clear: both;
}
.foot {
  width: 950px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999;
}
.footlist {
  width: 142px;
  margin-bottom: 20px;
  margin-right: 18px;
  float: left;
}
.footlist2 {
  float: right;
  width: 289px;
  color: #a1ed10;
  text-align: right;
}
.footlist2 span {
  font-family: 'HittheRoadRegular';
  font-size: 20px;
  color: #c1ef6d;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}
.footlist h6 {
  font-family: 'HittheRoadRegular';
  font-weight: normal;
  font-size: 16px;
  color: #c1ef6d;
  padding-bottom: 5px;
  border-bottom: solid 1px #c1ef6d;
  margin-bottom: 5px;
}
.footlist ul {
  list-style: none;
  color: #a1ed10;
  line-height: 20px;
}
.socials a {
  background-repeat: no-repeat;
  background-position: left top;
  padding-left: 27px;
  height: 21px;
  margin-bottom: 5px;
  display: block;
}
#facebook2 {
  background-image: url('/images/facebook2.jpg');
}
#facebook2:hover {
  background-position: left -21px;
}
#google2 {
  background-image: url('/images/google2.jpg');
}
#google2:hover {
  background-position: left -21px;
}
#twitter2 {
  background-image: url('/images/twitter2.jpg');
}
#twitter2:hover {
  background-position: left -21px;
}
#youtube2 {
  background-image: url('/images/youtube2.jpg');
}
#youtube2:hover {
  background-position: left -21px;
}
.copyright {
  height: 25px;
  width: 100%;
  clear: both;
  color: #c2ef6f;
}
.copy {
  float: left;
  display: block;
}
.modo {
  float: right;
  display: block;
}
.footer a {
  color: #a1ed10;
  text-decoration: none;
}
.footer a:hover {
  color: #DDF7AC;
  text-decoration: underline;
}
/* FESTIVALS PAGE CSS STARTS HERE */
#festival {
  width: 950px;
  padding-top: 10px;
}
.image1 {
  float: left;
  width: 372px;
  height: 342px;
  margin-right: 15px;
  background-color: #fff;
  margin-bottom: 90px;
  position: relative;
}
.boots {
  position: absolute;
  bottom: -85px;
  right: -70px;
}
.photo1 {
  background-image: url('/images/photosBG.png');
  background-repeat: no-repeat;
  height: 373px;
  width: 401px;
}
.photo1 img {
  -moz-box-shadow: 0 0 3px #888;
  -webkit-box-shadow: 0 0 3px #888;
  box-shadow: 0 0 3px #888;
  border: solid #fff 6px;
  margin-left: 10px;
  margin-top: 10px;
}
.textbox1 {
  float: right;
  width: 525px;
  height: 320px;
  margin-bottom: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}
.textbox1 h1,
.textbox2 h1 {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
}
.textbox1 h2,
.textbox2 h2 {
  color: #6443b5;
  font-size: 18px;
  font-family: 'twentytwelve_twentytwelveslab';
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  padding-bottom: 15px;
}
ul.festival_logos {
  list-style: none;
  padding-top: 12px;
}
ul.festival_logos li {
  float: left;
}
ul.festival_logos li:not(:last-child) {
  margin-right: 10px;
}
.textbox2 {
  float: left;
  width: 335px;
  height: 380px;
  padding: 10px;
  margin-bottom: 15px;
}
.image2 {
  float: right;
  height: 373px;
  width: 559px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #fff;
  margin-bottom: 15px;
  position: relative;
}
.photo2 {
  background-image: url('/images/photo2BG.jpg');
  height: 373px;
  width: 559px;
  background-repeat: no-repeat;
}
.photo2 img {
  -moz-box-shadow: 0 0 3px #888;
  -webkit-box-shadow: 0 0 3px #888;
  box-shadow: 0 0 3px #888;
  border: solid #fff 6px;
  margin-left: 10px;
  margin-top: 10px;
}
.champagne {
  position: absolute;
  bottom: -55px;
  left: -25px;
}
.events {
  clear: both;
}
.event {
  float: left;
  width: 150px;
  margin-right: 10px;
}
/* TERMS PAGE CSS STARTS HERE */
.minishare {
  background-color: #1497c4;
  margin-bottom: 15px;
  color: #fff;
  padding: 10px;
  min-height: 115px;
  font-family: 'HittheRoadRegular';
  font-weight: normal;
  font-size: 22px;
  letter-spacing: -0.5px;
  line-height: normal;
  margin-bottom: 10px;
}
.minirange {
  background-color: #ff8d1a;
  margin-bottom: 15px;
  color: #fff;
  padding: 10px;
  min-height: 115px;
  font-family: 'HittheRoadRegular';
  font-weight: normal;
  font-size: 22px;
  letter-spacing: -0.5px;
  line-height: normal;
  margin-bottom: 10px;
}
.miniguide {
  background-color: #70a539;
  margin-bottom: 15px;
  color: #fff;
  padding: 10px;
  min-height: 115px;
  font-family: 'HittheRoadRegular';
  font-weight: normal;
  font-size: 22px;
  letter-spacing: -0.5px;
  line-height: normal;
  margin-bottom: 10px;
}
.minishare img,
.minirange img,
.miniguide img {
  float: left;
  margin-right: 10px;
}
#cons .main1 {
  width: 615px;
  height: 210px;
  background-color: #fff;
  padding: 6px;
  -moz-box-shadow: 0 0 3px #888;
  -webkit-box-shadow: 0 0 3px #888;
  box-shadow: 0 0 3px #888;
  margin-bottom: 15px;
  margin-top: 15px;
}
#cons ul,
.textbox2 ul {
  margin-left: 15px;
  color: #6443b5;
  font-size: 16px;
}
#cons ul li {
  margin-bottom: 10px;
}
#corporate ul li {
  margin-bottom: 5px;
}
#cons ul li span,
.textbox2 ul li span {
  color: #666;
  font-size: 12px;
}
#cons ul li ul,
.textbox2 ul li ul {
  margin-left: 20px;
  margin-top: 10px;
}
#cons h4,
#cons h3 {
  font-family: 'twentytwelve_twentytwelveslab';
  color: #6342b4;
  font-size: 18px;
  padding-bottom: 5px;
  font-weight: normal;
}
.alpha li {
  padding-bottom: 8px;
}
/* CONTACT PAGE CSS STARTS HERE */
.map {
  width: 618px;
  height: 368px;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
  padding: 5px;
  border: solid 1px #ddd;
}
#contactus a {
  color: #6443b5;
  text-decoration: none;
}
#contactus a:hover {
  color: #86a702;
  text-decoration: underline;
}
#form {
  background-color: #eee;
  margin-top: 15px;
  color: #666;
  padding-left: 14px;
  padding-right: 14px;
  width: 277px;
  padding-top: 54px;
  position: relative;
  border: solid 1px #ddd;
}
#form h4 {
  display: block;
  background-color: #6342b4;
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 24px;
  width: 281px;
  padding-top: 12px;
  padding-right: 11px;
  padding-bottom: 12px;
  padding-left: 13px;
}
/* TOP DESTINATIONS PAGE CSS STARTS HERE */
#destination {
  width: 940px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
}
#destination h2 {
  color: #6443b5;
  font-size: 18px;
  font-family: 'twentytwelve_twentytwelveslab';
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  padding-bottom: 15px;
}
/* vehicle range starts here*/
.fleet {
  background-repeat: no-repeat;
  height: 230px;
  width: 915px;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
}
#motors {
  width: 922px;
  background-image: url('/images/range-head2.jpg');
  background-repeat: no-repeat;
  height: 230px;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 5px;
}
#termscons {
  background-image: url('/images/new-terms.jpg');
  background-repeat: no-repeat;
}
#optional {
  background-image: url('/images/new-optional.jpg');
  background-repeat: no-repeat;
}
#questions {
  background-image: url('/images/new-faq.jpg');
  background-repeat: no-repeat;
}
#destinationhead {
  background-image: url('/images/motorhome-holidays.jpg');
  background-repeat: no-repeat;
}
.head1 {
  font-size: 34px;
  display: inline-block;
  font-family: 'HittheRoadRegular';
  font-weight: normal;
  text-transform: uppercase;
  background: url('../../img/purple-caption-bg.png');
  margin-bottom: 5px;
  padding: 10px;
  color: #fff;
  text-align: left;
}
.head2 {
  font-size: 34px;
  font-weight: normal;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'HittheRoadRegular';
  background: url('../../img/purple-caption-bg.png');
  margin-bottom: 5px;
  padding: 10px;
  color: #fff;
}
.head3 {
  font-size: 14px;
  font-weight: normal;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  background: url('../../img/purple-caption-bg.png');
  margin-bottom: 5px;
  padding: 10px;
  color: #fff;
}
#rangepage {
  width: 945px;
}
.price {
  position: absolute;
  top: -5px;
  right: -8px;
  width: 81px;
  height: 81px;
}
.icons {
  list-style: none;
  text-indent: 0px;
}
.icons li {
  padding-left: 55px;
  height: 30px;
  display: block;
  padding-top: 15px;
  font-size: 14px;
  margin-bottom: 0px !important;
}
#satnav {
  background-image: url('/images/sat-nav.jpg');
  background-repeat: no-repeat;
  background-position: left top;
}
#chairs {
  background-image: url('/images/chairs.jpg');
  background-repeat: no-repeat;
  background-position: left top;
}
#awning {
  background-image: url('/images/awning.jpg');
  background-repeat: no-repeat;
  background-position: left top;
}
#driver {
  background-image: url('/images/driver.jpg');
  background-repeat: no-repeat;
  background-position: left top;
}
#accident {
  background-image: url('/images/accident.jpg');
  background-repeat: no-repeat;
  background-position: left top;
}
#europe {
  background-image: url('/images/europe.jpg');
  background-repeat: no-repeat;
  background-position: left top;
  padding-top: 0px;
  height: 45px;
}
.breadcrumbs {
  padding: 8px;
  background-color: #eee;
  border: solid 1px #ddd;
  margin-bottom: 20px;
  color: #000;
}
.breadcrumbs a {
  color: #666;
  text-decoration: none;
}
.breadcrumbs a:hover {
  color: #000;
  text-decoration: underline;
}
.zoom {
  text-align: right;
  padding-bottom: 8px;
  padding-right: 5px;
  background-image: url('/images/zoom.png');
  background-repeat: no-repeat;
  background-position: 340px top;
}
.thumbs img {
  border: solid #ddd 3px;
  margin-right: 4px;
}
.thumbs img:hover {
  opacity: 0.5;
}
ul.submenu {
  list-style: none;
  border-bottom: solid 2px #000;
  display: block;
  height: 34px;
  margin-top: 10px;
  margin-bottom: 13px;
}
ul.submenu li {
  display: block;
  float: left;
  text-align: center;
  padding: 8px;
  font-family: 'twentytwelve_twentytwelveslab';
  font-size: 18px;
  margin-right: 10px;
  -moz-border-radius: 6px 6px 0px 0px;
  -webkit-border-radius: 6px 6px 0px 0px;
  border-radius: 6px 6px 0px 0px;
  /* future proofing */
  -khtml-border-radius: 6px 6px 0px 0px;
  /* for old Konqueror browsers */
  background-color: #000;
}
ul.submenu a {
  color: #fff;
  text-decoration: none;
}
ul.submenu a:hover {
  color: #999;
}
ul.newtab {
  display: block;
  position: absolute;
  right: 38px;
  top: 582px;
}
ul.newtab44 {
  display: block;
  position: absolute;
  right: 38px;
  top: 681px;
}
li.tab a {
  display: block;
  float: left;
  text-align: center;
  padding: 8px;
  font-family: 'twentytwelve_twentytwelveslab';
  font-size: 18px;
  margin-right: 10px;
  -moz-border-radius: 6px 6px 0px 0px;
  -webkit-border-radius: 6px 6px 0px 0px;
  border-radius: 6px 6px 0px 0px;
  /* future proofing */
  -khtml-border-radius: 6px 6px 0px 0px;
  /* for old Konqueror browsers */
  background-color: #000;
  color: #fff;
  text-decoration: none;
  list-style: none;
}
li.tab a:hover {
  color: #999;
}
.rates,
.features,
.spec {
  top: 0px;
  left: 0px;
}
.rates h5 {
  font-size: 20px;
  font-family: 'twentytwelve_twentytwelveslab';
  margin: 0px;
  padding: 0px 0px 15px;
  font-weight: normal;
  color: #000;
}
.extra-extra {
  list-style: none;
}
.extra-extra li {
  min-height: 143px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  display: block;
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  clear: both;
  color: #666 !important;
}
.extra-extra img {
  float: left;
  display: block;
  margin-right: 15px;
}
.extra-extra strong {
  font-size: 16px;
  line-height: 22px;
  color: #6443b5;
  display: block;
}
.sundaytimes {
  background-image: url('/images/sunday-times.jpg');
  background-repeat: no-repeat;
  width: 130px;
  height: 115px;
  padding-top: 139px;
  padding-left: 7px;
  padding-right: 150px;
}
.sun {
  background-image: url('/images/sun.jpg');
  background-repeat: no-repeat;
  width: 280px;
  height: 60px;
  padding-top: 145px;
  padding-left: 7px;
}
.bottom-content-area {
  margin-top: 15px;
  border-top: dotted 1px #ccc;
  padding-top: 15px;
  clear: both;
}
.bottom-content-area strong {
  font-size: 18px;
  line-height: normal;
  font-family: 'twentytwelve_twentytwelveslab';
  display: block;
  margin: 10px 0px;
  font-weight: normal;
  color: #6443b5;
}
.bottom-content-area .cola {
  float: left;
  width: 296px;
}
.bottom-content-area .colb {
  float: left;
  border-left: dotted 1px #ccc;
  border-right: dotted 1px #ccc;
  padding: 0px 15px;
  margin: 0 15px;
  width: 296px;
}
.bottom-content-area .colc {
  float: left;
  width: 295px;
}
.bottom-content-area a {
  color: #6443b5;
}
#offer-banners {
  margin-top: -10px;
  height: 198px;
  overflow: hidden;
  position: relative;
}
#offer-banners img {
  position: absolute;
  z-index: 1;
}
#offer-banners img.active {
  z-index: 3;
}
table {
  display: table;
  border-collapse: separate;
  border-spacing: 8px;
  border-color: grey;
}
.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}
.animated.hinge {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }
  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}
@-moz-keyframes tada {
  0% {
    -moz-transform: scale(1);
  }
  10%,
  20% {
    -moz-transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -moz-transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -moz-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -moz-transform: scale(1) rotate(0);
  }
}
@-o-keyframes tada {
  0% {
    -o-transform: scale(1);
  }
  10%,
  20% {
    -o-transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -o-transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -o-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -o-transform: scale(1) rotate(0);
  }
}
@keyframes tada {
  0% {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
.tada {
  -webkit-animation-name: tada;
  -moz-animation-name: tada;
  -o-animation-name: tada;
  animation-name: tada;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
}
/* The slider */
.iviewSlider {
  width: 950px;
  height: 250px;
  overflow: hidden;
}
/* The timer in the Slider */
#iview-timer {
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  cursor: pointer;
}
#iview-timer div {
  border-radius: 3px;
}
/* The Preloader in the Slider */
#iview-preloader {
  position: absolute;
  z-index: 1000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: #000 1px solid;
  padding: 1px;
  width: 100px;
  height: 3px;
}
#iview-preloader div {
  float: left;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 3px;
  background: #000;
  width: 0px;
}
/* The strips and boxes in the Slider */
.iview-strip {
  display: block;
  position: absolute;
  z-index: 5;
}
.iview-block {
  display: block;
  position: absolute;
  z-index: 5;
}
/* Direction nav styles (e.g. Next & Prev) */
.iview-directionNav a {
  position: absolute;
  top: 45%;
  z-index: 9;
  cursor: pointer;
}
.iview-prevNav {
  left: 0px;
}
.iview-nextNav {
  right: 0px;
}
/* Control nav styles (e.g. 1,2,3...) */
.iview-controlNav {
  position: absolute;
  z-index: 9;
}
.iview-controlNav a {
  z-index: 9;
  cursor: pointer;
}
.iview-controlNav a.active {
  font-weight: bold;
}
.iview-controlNav .iview-items ul {
  list-style: none;
}
.iview-controlNav .iview-items ul li {
  display: inline;
  position: relative;
}
.iview-controlNav .iview-tooltip {
  position: absolute;
}
/* The captions in the Slider */
.iview-caption {
  position: absolute;
  z-index: 4;
  overflow: hidden;
  cursor: default;
}
#iview {
  display: block;
  height: 250px;
  position: relative;
  width: 950px;
}
#iview .iviewSlider {
  display: block;
  height: 250px;
  overflow: hidden;
  width: 950px;
}
.iview-controlNav {
  bottom: 30px;
  height: 44px;
  left: 347px;
  position: absolute;
}
.iview-controlNav a {
  text-indent: -9999px;
}
.iview-controlNav a.iview-controlPrevNav {
  background: hsla(0, 0%, 0%, 0) url("/img/slider-controls.png") no-repeat scroll 0 0;
  float: left;
  height: 44px;
  width: 50px;
}
.iview-controlNav a.iview-controlNextNav {
  background: hsla(0, 0%, 0%, 0) url("/img/slider-controls.png") no-repeat scroll -50px 0;
  float: left;
  height: 44px;
  width: 50px;
}
.iview-controlNav div.iview-items {
  background: hsla(0, 0%, 0%, 0) url("/img/nav-bg.png") repeat scroll 0 0;
  float: left;
  height: 44px;
  padding: 0 10px;
}
.iview-controlNav a.iview-control {
  background: hsla(0, 0%, 0%, 0) url("/img/bullets.png") no-repeat scroll 0 0;
  float: left;
  height: 12px;
  line-height: 0;
  padding: 0;
  width: 11px;
}
.iview-controlNav a.iview-control.active {
  background-position: 0 -12px;
}
#iview div.iview-directionNav {
  left: 0;
  position: absolute;
  top: 70%;
  width: 100%;
  z-index: 9;
}
#iview div.iview-directionNav a {
  background-image: url("/img/bg_direction_nav_white.png");
  cursor: pointer;
  display: block;
  height: 27px;
  position: absolute;
  text-indent: -9999px;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: left, right;
  transition-timing-function: ease-in-out;
  width: 27px;
}
#iview div.iview-directionNav a.iview-nextNav {
  background-position: 27px 0;
  right: 27px;
}
#iview div.iview-directionNav a.iview-prevNav {
  background-position: 0 0;
  left: 27px;
}
#iview.iview-hover div.iview-directionNav a.iview-nextNav {
  right: 20px;
}
#iview.iview-hover div.iview-directionNav a.iview-prevNav {
  left: 20px;
}
.iview-caption {
  color: hsl(0, 0%, 100%);
  width: 600px;
}
.iview-caption.caption h1 {
  background: hsla(0, 0%, 0%, 0) url("/img/purple-caption-bg.png") repeat scroll 0 0;
  display: inline-block;
  font-family: "HittheRoadRegular";
  font-size: 34px;
  font-weight: normal;
  margin-bottom: 5px;
  padding: 10px;
  text-transform: uppercase;
}
.caption2 h1 {
  text-align: right;
}
.iview-caption.caption h2 {
  background: hsla(0, 0%, 0%, 0) url("/img/purple-caption-bg.png") repeat scroll 0 0;
  display: inline-block;
  font-family: "HittheRoadRegular";
  font-size: 34px;
  font-weight: normal;
  margin-bottom: 5px;
  padding: 10px;
  text-transform: uppercase;
}
.iview-caption.caption h3 {
  background: hsla(0, 0%, 0%, 0) url("/img/purple-caption-bg.png") repeat scroll 0 0;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
  padding: 10px;
  text-transform: uppercase;
}
.iview-caption.blackcaption {
  background: hsl(0, 0%, 0%) none repeat scroll 0 0;
  box-shadow: 10px 10px 15px 0 hsla(0, 0%, 0%, 0.7);
  text-shadow: none;
}
#iview-preloader {
  border: 1px solid hsl(0, 0%, 40%);
  width: 150px;
}
#iview-preloader div {
  background: hsl(0, 0%, 40%) none repeat scroll 0 0;
}
.iview-controlNav div.iview-items ul li {
  float: left;
  height: 12px;
  line-height: 0;
  margin: 16px 3px 3px;
  padding: 0;
  width: 11px;
}
#iview #iview-tooltip {
  background: hsla(0, 0%, 0%, 0) url("/img/tooltip.png") no-repeat scroll 0 0;
  bottom: 30px;
  display: none;
  height: 90px;
  left: -67px;
  padding: 10px;
  position: absolute;
  width: 124px;
  z-index: 100;
}
#iview #iview-tooltip div.holder {
  border-radius: 2px;
  display: block;
  height: 84px;
  overflow: hidden;
  width: 124px;
}
#iview #iview-tooltip div.holder div.container {
  display: block;
  width: 4000px;
}
#iview #iview-tooltip div.holder div.container div {
  display: block;
  float: left;
  height: 84px;
  left: -50%;
  overflow: hidden;
  text-align: center;
  width: 124px;
}
#iview #iview-tooltip div.holder div.container div img {
  height: 84px;
  margin: 0 auto;
}
@media screen and (max-width:1023px) {
  #iview {
    width: 100vw;
    height: auto;
  }
  #iview .iviewSlider {
    width: 100vw;
    height: auto;
  }
}
.node-type-destinations .location {
  float: left;
  width: 297px;
  height: auto;
  margin-right: 24px;
  padding-bottom: 25px;
}
.node-type-destinations .location h4 {
  font-family: 'twentytwelve_twentytwelveslab';
  color: #6342b4;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 7px;
}
.node-type-destinations .location img {
  margin-bottom: 10px;
}
.node-type-destinations .location p {
  margin-top: 14px;
}
.node-type-destinations .location.no-margin {
  margin-right: 0;
}
@media screen and (max-width:1023px) {
  .node-type-destinations #destination {
    width: 100vw;
    margin: 0;
  }
  .node-type-destinations .fleet {
    width: 100%;
    height: auto;
    padding: 14px;
    margin: 14px auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .node-type-destinations .fleet .head1,
  .node-type-destinations .fleet .head2,
  .node-type-destinations .fleet .head3 {
    text-align: center;
    width: 100%;
  }
  .node-type-destinations .fleet .head1,
  .node-type-destinations .fleet .head2 {
    font-size: 24px;
    line-height: 27px;
  }
  .node-type-destinations .location {
    float: none;
    display: block;
    width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    padding: 0 5vw 25px;
  }
  .node-type-destinations .location img {
    margin-bottom: 10px;
    max-width: 100%;
    height: auto;
  }
  .node-type-destinations .location.no-margin {
    margin-right: auto;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .node-type-destinations .location {
    width: 48%;
    margin: 0 1% 3vw 1%;
    float: left;
    min-height: 76vh;
    padding: 0 2vw;
  }
}
@media screen and (max-width:1023px) {
  .page-node-10 .vehicles {
    width: 100vw;
    margin: 0;
    height: auto;
    display: block;
    float: none;
  }
  .page-node-10 h3 {
    text-align: center;
    margin-top: 14px;
    margin-bottom: 0;
  }
  .page-node-10 table {
    width: 100%;
    table-layout: fixed;
    font-size: 0.9em;
    border-collapse: collapse;
    border: none;
  }
  .page-node-10 table tr td {
    border: 1px solid white;
  }
  .page-node-10 .right {
    margin: 0;
    width: 100vw;
  }
}
@media screen and (max-width:719px) and  (orientation : portrait) {
  .page-node-10 .right table {
    font-size: 0.7em;
  }
}
.front .column p {
  margin-top: 14px;
}
.front .youtube-check:checked ~ .youtube-visible {
  display: none;
}
.front .youtube-visible {
  position: fixed;
  bottom: 0;
  right: 0;
  background: white;
  padding: 10px 10px 2px 10px;
  z-index: 10;
  width: 20vw;
  height: auto;
  border: 1px solid #1497c4;
}
.front .youtube-visible object {
  width: 100%;
  height: auto;
}
.front .youtube-visible label {
  background: none;
  border: none;
}
@media screen and (max-width:1023px) {
  .front #offer-banners {
    max-width: 100%;
    height: auto;
  }
  .front .column {
    width: 80%;
    height: auto;
    margin: 2vh 10%;
  }
  .front .column div {
    height: auto;
  }
  .front .column .greenbutton {
    margin-top: 10px;
    position: relative;
    bottom: 0;
    left: 0;
  }
  .front #col1 {
    margin-top: 5vh;
  }
  .front #col2 {
    margin-left: 10%;
    margin-right: 10%;
  }
  .front .left,
  .front .right {
    float: none;
    display: block;
    width: 100%;
  }
  .front .left {
    width: 80%;
    margin: 2vh 10%;
    padding: 10px 0;
  }
  .front .left .sundaytimes,
  .front .left .sun,
  .front .left iframe {
    width: 100%;
    height: auto;
    background-position-x: center;
    margin-bottom: 14px;
  }
  .front .right {
    padding: 10px 0;
    margin: 0;
  }
  .front .right h1 {
    text-align: center;
  }
  .front .right .blocks li {
    width: 49vw;
    margin: 0.5vw;
    background-position: 50% 0;
    background-color: white;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .front .right .blocks li div {
    width: 100%;
  }
  .front .right .blocks li:not(:last-child) {
    margin: 0.5vw;
  }
  .front .right .col1,
  .front .right .col2 {
    width: 80%;
    margin: 2vh 10%;
    border: none;
  }
  .front .bottom-content-area .cola,
  .front .bottom-content-area .colb,
  .front .bottom-content-area .colc {
    width: 80%;
    margin: 2vh 10%;
    float: none;
    padding: 0 14px;
    text-align: center;
    border: none;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .front .youtube-visible {
    width: 50vw;
  }
  .front .youtube-visible label {
    padding: 10px;
    margin: 5px 0;
    display: block;
  }
  .front .column {
    width: 31vw;
    float: left;
    height: auto;
    margin: 2vh 1vw;
  }
  .front .column div {
    height: auto;
  }
  .front .column .greenbutton {
    margin-top: 10px;
    position: relative;
    bottom: 0;
    left: 0;
  }
  .front #col1 {
    margin-top: 2vh;
  }
  .front #col2 {
    margin-left: 1vw;
    margin-right: 1vw;
  }
  .front .left {
    width: 100vw;
    margin: 2vh 0;
    *zoom: 1;
  }
  .front .left .sundaytimes,
  .front .left .sun,
  .front .left .offers,
  .front .left .extra {
    width: 48vw;
    margin: 2vh 1vw;
    float: left;
    text-align: center;
    border: none;
    height: 21vh;
  }
  .front .left iframe {
    width: 47vw;
    margin: 2vh 0.8vw;
    float: left;
    text-align: center;
    border: none;
    height: 21vh;
  }
  .front .left .offers,
  .front .left .extra {
    background: none;
  }
  .front .left:before,
  .front .left:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  .front .left:after {
    clear: both;
  }
  .front .right {
    width: 100vw;
  }
  .front .right h1 {
    text-align: center;
  }
  .front .right .blocks {
    *zoom: 1;
  }
  .front .right .blocks li {
    width: 32vw;
    height: 32vw;
  }
  .front .right .blocks li:nth-of-type(4) {
    margin-left: 33.5vw;
  }
  .front .right .blocks:before,
  .front .right .blocks:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  .front .right .blocks:after {
    clear: both;
  }
  .front .right .col2 .euro {
    width: 50vw;
    max-width: 287px;
    margin: auto;
  }
  .front .bottom-content-area .cola,
  .front .bottom-content-area .colb,
  .front .bottom-content-area .colc {
    width: 31vw;
    float: left;
    margin: 2vh 1vw;
  }
  .front .bottom-content-area .cola a,
  .front .bottom-content-area .colb a,
  .front .bottom-content-area .colc a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}
@media screen and (max-width:719px) {
  .front .youtube-visible {
    width: 100vw;
  }
  .front .left .sundaytimes {
    padding-right: 70%;
  }
  .front .bottom-content-area .cola img,
  .front .bottom-content-area .colb img {
    padding: 0px 55px;
  }
  .front .bottom-content-area .colc img {
    padding: 0px 65px;
  }
}
.page-node-37 {
  /*.vehicles:nth-child(even){
        background-image: url("/images/shadowNEW.jpg");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
        color: rgb(255, 255, 255);
        float: left;
        margin-left: 5px;
        padding: 5px 5px 27px;
        position: relative;
        text-align: center;
        width: 457px;
    }*/
}
.page-node-37 .right {
  width: 100%;
  float: none;
}
.page-node-37 .vehicles {
  background-image: url("/images/shadowNEW.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: #fff;
  margin-left: 6px;
  padding: 5px 5px 27px;
  position: relative;
  text-align: center;
  width: 457px;
  float: left;
}
.page-node-37 .vehicles h2 {
  color: #fff;
  margin-top: 0px;
  font-size: 28px;
  font-family: 'twentytwelve_twentytwelveslab';
  line-height: normal;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.page-node-37 .vehicles h4 {
  font-size: 22px;
  font-family: 'twentytwelve_twentytwelveslab';
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  padding-bottom: 15px;
}
.page-node-37 .vehicles .inside {
  display: block;
  padding: 10px;
}
@media screen and (max-width:1023px) {
  .page-node-37 .right {
    margin: 0;
  }
  .page-node-37 #motors {
    width: 100vw;
    height: auto;
    padding: 14px;
    margin: 14px auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .page-node-37 #motors .head1,
  .page-node-37 #motors .head2,
  .page-node-37 #motors .head3 {
    text-align: center;
    width: 100%;
  }
  .page-node-37 #motors .head1,
  .page-node-37 #motors .head2 {
    font-size: 24px;
    line-height: 27px;
  }
  .page-node-37 .vehicles {
    width: 100%;
    margin: 0;
    height: auto;
    display: block;
    float: none;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .page-node-37 .motorhome-range-block {
    *zoom: 1;
  }
  .page-node-37 .motorhome-range-block .vehicles {
    width: 48%;
    margin: 0 1%;
    float: left;
  }
  .page-node-37 .motorhome-range-block:before,
  .page-node-37 .motorhome-range-block:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  .page-node-37 .motorhome-range-block:after {
    clear: both;
  }
}
.node-type-essential-guide p {
  margin-bottom: 14px;
}
@media screen and (max-width:1023px) {
  .node-type-essential-guide .content {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .node-type-essential-guide .content .fleet {
    width: 100%;
    height: auto;
    padding: 14px;
    margin: 14px auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .node-type-essential-guide .content .fleet .head1,
  .node-type-essential-guide .content .fleet .head2,
  .node-type-essential-guide .content .fleet .head3 {
    text-align: center;
    width: 100%;
  }
  .node-type-essential-guide .content .fleet .head1,
  .node-type-essential-guide .content .fleet .head2 {
    font-size: 24px;
    line-height: 27px;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  #cons ul {
    font-size: 18px;
    line-height: 22px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  #cons ul li span {
    font-size: 18px;
    line-height: 22px;
  }
}
.node-type-extras .right p {
  margin-top: 14px;
}
.node-type-extras .extra-extra {
  margin-top: 14px;
}
@media screen and (max-width:1023px) {
  .node-type-extras .fleet {
    width: 100vw;
    height: auto;
    padding: 14px;
    margin: 14px auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .node-type-extras .fleet .head1,
  .node-type-extras .fleet .head2,
  .node-type-extras .fleet .head3 {
    text-align: center;
    width: 100%;
  }
  .node-type-extras .fleet .head1,
  .node-type-extras .fleet .head2 {
    font-size: 24px;
    line-height: 27px;
  }
  .node-type-extras #cons .extra-extra {
    margin: 0;
  }
  .node-type-extras #cons .extra-extra li {
    width: 100%;
    margin-bottom: 28px;
  }
  .node-type-extras #cons .extra-extra li img {
    width: 100%;
    height: auto;
    padding: 3vw;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .node-type-extras .fleet {
    width: 100vw;
    height: auto;
    padding: 14px;
    margin: 14px auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .node-type-extras .fleet .head1,
  .node-type-extras .fleet .head2,
  .node-type-extras .fleet .head3 {
    text-align: center;
    width: 100%;
  }
  .node-type-extras .fleet .head1,
  .node-type-extras .fleet .head2 {
    font-size: 24px;
    line-height: 27px;
  }
  .node-type-extras #cons .extra-extra {
    margin: 0;
  }
  .node-type-extras #cons .extra-extra li {
    width: 100%;
    margin-bottom: 28px;
    font-size: inherit;
  }
  .node-type-extras #cons .extra-extra li img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}
@media screen and (max-width:719px) {
  .node-type-extras #cons .extra-extra {
    margin: 0;
  }
  .node-type-extras #cons .extra-extra li {
    width: 100%;
    padding: 0 12vw;
    margin-bottom: 38px;
    font-size: 90%;
  }
  .node-type-extras #cons .extra-extra li img {
    width: auto;
    max-width: 100%;
    height: auto;
    padding: 0;
  }
  .node-type-extras #cons .extra-extra li p {
    margin-top: 7px;
  }
  .node-type-extras #cons .extra-extra li:first-of-type {
    margin-top: 30px;
  }
}
@media screen and (max-width:1023px) {
  .node-type-faq .content {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .node-type-faq .content .fleet {
    width: 100%;
    height: auto;
    padding: 14px;
    margin: 14px auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .node-type-faq .content .fleet .head1,
  .node-type-faq .content .fleet .head2,
  .node-type-faq .content .fleet .head3 {
    text-align: center;
    width: 100%;
  }
  .node-type-faq .content .fleet .head1,
  .node-type-faq .content .fleet .head2 {
    font-size: 24px;
    line-height: 27px;
  }
  .node-type-faq .content .map {
    width: 100%;
    max-height: 65vh;
    margin-bottom: 14px;
  }
  .node-type-faq.page-node-36 .content {
    padding: 0 10px;
  }
}
.node-type-festival-corporate #festival .textbox1 .poi,
.node-type-festival-corporate #festival .textbox2 .poi {
  float: left;
}
.node-type-festival-corporate #festival .textbox1 .poi:nth-of-type(2),
.node-type-festival-corporate #festival .textbox2 .poi:nth-of-type(2) {
  margin-left: 50px;
}
@media screen and (max-width:1023px) {
  .node-type-festival-corporate #festival {
    width: 100%;
    margin: 0;
  }
  .node-type-festival-corporate #festival .image1,
  .node-type-festival-corporate #festival .image2 {
    float: none;
    width: 100%;
    margin: 0;
    padding: 5px 8px;
    height: auto;
  }
  .node-type-festival-corporate #festival .image1 .photo1,
  .node-type-festival-corporate #festival .image2 .photo1,
  .node-type-festival-corporate #festival .image1 .photo2,
  .node-type-festival-corporate #festival .image2 .photo2 {
    width: 100%;
    height: auto;
  }
  .node-type-festival-corporate #festival .image1 .photo1 img,
  .node-type-festival-corporate #festival .image2 .photo1 img,
  .node-type-festival-corporate #festival .image1 .photo2 img,
  .node-type-festival-corporate #festival .image2 .photo2 img {
    margin: 0;
  }
  .node-type-festival-corporate #festival .image1 .boots,
  .node-type-festival-corporate #festival .image2 .boots,
  .node-type-festival-corporate #festival .image1 .champagne,
  .node-type-festival-corporate #festival .image2 .champagne {
    bottom: -15%;
    right: 1%;
    left: auto;
    width: 60%;
  }
  .node-type-festival-corporate #festival .textbox1,
  .node-type-festival-corporate #festival .textbox2 {
    width: 100%;
    height: auto;
    float: none;
    margin: 10% auto 10px;
  }
  .node-type-festival-corporate #festival .textbox1 .poi,
  .node-type-festival-corporate #festival .textbox2 .poi {
    float: none;
  }
  .node-type-festival-corporate #festival .textbox1 .poi:nth-of-type(2),
  .node-type-festival-corporate #festival .textbox2 .poi:nth-of-type(2) {
    margin-left: 15px;
  }
  .node-type-festival-corporate .festival_logos li {
    margin-right: 9px;
  }
  .node-type-festival-corporate .festival_logos li:not(:last-child) {
    margin-right: 9px;
  }
  .node-type-festival-corporate .events {
    width: 90%;
    margin: 0 10%;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .node-type-festival-corporate #festival {
    width: 80%;
    margin: 0 10%;
  }
  .node-type-festival-corporate #festival .image1,
  .node-type-festival-corporate #festival .image2 {
    float: none;
    width: 100%;
    margin: 0;
    padding: 5px 8px;
    height: auto;
  }
  .node-type-festival-corporate #festival .image1 .photo1,
  .node-type-festival-corporate #festival .image2 .photo1,
  .node-type-festival-corporate #festival .image1 .photo2,
  .node-type-festival-corporate #festival .image2 .photo2 {
    width: 100%;
    height: auto;
  }
  .node-type-festival-corporate #festival .image1 .photo1 img,
  .node-type-festival-corporate #festival .image2 .photo1 img,
  .node-type-festival-corporate #festival .image1 .photo2 img,
  .node-type-festival-corporate #festival .image2 .photo2 img {
    margin: 0;
  }
  .node-type-festival-corporate #festival .image1 .boots,
  .node-type-festival-corporate #festival .image2 .boots,
  .node-type-festival-corporate #festival .image1 .champagne,
  .node-type-festival-corporate #festival .image2 .champagne {
    bottom: -15%;
    right: 1%;
    left: auto;
    width: 60%;
  }
  .node-type-festival-corporate #festival .textbox1,
  .node-type-festival-corporate #festival .textbox2 {
    width: 100%;
    height: auto;
    float: none;
    margin: 10% auto 10px;
  }
  .node-type-festival-corporate #festival .textbox1 .poi,
  .node-type-festival-corporate #festival .textbox2 .poi {
    float: none;
    font-size: 18px;
    line-height: 22px;
  }
  .node-type-festival-corporate #festival .textbox1 .poi:nth-of-type(2),
  .node-type-festival-corporate #festival .textbox2 .poi:nth-of-type(2) {
    margin-left: 15px;
  }
  .node-type-festival-corporate #festival .textbox1 .poi li,
  .node-type-festival-corporate #festival .textbox2 .poi li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .node-type-festival-corporate #festival .textbox1 .poi span,
  .node-type-festival-corporate #festival .textbox2 .poi span {
    font-size: 18px;
    line-height: 22px;
  }
  .node-type-festival-corporate .festival_logos li {
    margin-right: 9px;
  }
  .node-type-festival-corporate .festival_logos li:not(:last-child) {
    margin-right: 9px;
  }
}
@media screen and (max-width:719px) and  (orientation : landscape) {
  .node-type-festival-corporate #festival .image1 .photo1,
  .node-type-festival-corporate #festival .image2 .photo1,
  .node-type-festival-corporate #festival .image1 .photo2,
  .node-type-festival-corporate #festival .image2 .photo2 {
    width: 65%;
    height: auto;
  }
  .node-type-festival-corporate #festival .image2 .champagne {
    width: 40%;
  }
}
.node-type-page.page-node-36 .right #map_canvas {
  height: 100%;
  width: 100%;
}
.node-type-page.page-node-36 .right #map_canvas a {
  color: #6443b5;
  text-decoration: none;
}
.node-type-page.page-node-36 .right #map_canvas a:hover {
  color: #5C8930;
  text-decoration: underline;
}
@media screen and (max-width:1023px) {
  .node-type-page .content {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .node-type-page .content .fleet {
    width: 100%;
    height: auto;
    padding: 14px;
    margin: 14px auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .node-type-page .content .fleet .head1,
  .node-type-page .content .fleet .head2,
  .node-type-page .content .fleet .head3 {
    text-align: center;
    width: 100%;
  }
  .node-type-page .content .fleet .head1,
  .node-type-page .content .fleet .head2 {
    font-size: 24px;
    line-height: 27px;
  }
  .node-type-page .content .map {
    width: 95%;
    max-height: 65vh;
    margin: 14px auto;
  }
  .node-type-page.page-node-36 .right {
    text-align: center;
    padding: 0;
    width: 90%;
    margin: 0 5%;
  }
  .node-type-page.page-node-36 .right img {
    padding: 2vw 12vw;
  }
  .node-type-page.page-node-36 .right strong img {
    padding: 2vw 22vw;
  }
  .node-type-page.page-node-36 .right .content {
    padding: 0 10px;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .node-type-page.page-node-36 .right {
    width: 70vw;
    margin: 0 15vw;
    text-align: center;
  }
  .node-type-page.page-node-36 .right img {
    padding: 2vw 12vw;
  }
  .node-type-page.page-node-36 .right strong img {
    padding: 2vw 22vw;
  }
  .node-type-page.page-node-36 .map {
    width: 90vw;
    max-height: 65vh;
    margin: 14px -10vw;
  }
  .node-type-page.page-node-10 .right {
    width: 100vw;
    margin: 0;
  }
}
.motorhome-range-block .vehicles.blue {
  background-color: #1497c4;
}
.motorhome-range-block .vehicles.blue h4 {
  color: #b8edff;
}
.motorhome-range-block .vehicles.blue a {
  color: #b8edff;
  font-size: 18px;
  font-family: 'twentytwelve_twentytwelveslab';
  text-decoration: none;
}
.motorhome-range-block .vehicles.blue aa:hover {
  color: #fff;
}
.motorhome-range-block .vehicles.blue:hover {
  background-color: #10789A;
}
.motorhome-range-block .vehicles.orange {
  background-color: #ff8d1a;
}
.motorhome-range-block .vehicles.orange h4 {
  color: #ffd1a2;
}
.motorhome-range-block .vehicles.orange a {
  font-size: 18px;
  font-family: 'twentytwelve_twentytwelveslab';
  color: #ffd1a2;
  text-decoration: none;
}
.motorhome-range-block .vehicles.orange a:hover {
  color: #fff;
}
.motorhome-range-block .vehicles.orange:hover {
  background-color: #E87400;
}
.motorhome-range-block .vehicles.green {
  background-color: #70a539;
}
.motorhome-range-block .vehicles.green h4 {
  color: #d7ffad;
}
.motorhome-range-block .vehicles.green a {
  font-size: 18px;
  font-family: 'twentytwelve_twentytwelveslab';
  color: #d7ffad;
  text-decoration: none;
}
.motorhome-range-block .vehicles.green a:hover {
  color: #fff;
}
.motorhome-range-block .vehicles.green:hover {
  background-color: #5C8930;
}
.motorhome-range-block .vehicles.purple {
  background-color: #6443b5;
}
.motorhome-range-block .vehicles.purple h2 {
  color: #ffffff;
}
.motorhome-range-block .vehicles.purple h4 {
  color: #cbb8ff;
}
.motorhome-range-block .vehicles.purple a {
  font-size: 18px;
  font-family: 'twentytwelve_twentytwelveslab';
  color: #cbb8ff;
  text-decoration: none;
}
.motorhome-range-block .vehicles.purple a:hover {
  color: #fff;
}
.motorhome-range-block .vehicles.purple:hover {
  background-color: #553892;
}
.node-type-productrange .description p {
  margin-top: 14px;
}
.node-type-productrange .blue .description {
  background: #1497c4;
}
.node-type-productrange .blue .description h3 {
  color: #b8edff;
}
.node-type-productrange .blue .description a {
  color: #b8edff;
  text-decoration: none;
}
.node-type-productrange .blue .description a:hover {
  color: #fff;
}
.node-type-productrange .blue .rates table tr:first-of-type td:nth-of-type(2),
.node-type-productrange .blue .rates table tr:first-of-type td:nth-of-type(3) {
  background: #1497c4;
}
.node-type-productrange .orange .description {
  background: #ff8d1a;
}
.node-type-productrange .orange .description h3 {
  color: #ffd1a2;
}
.node-type-productrange .orange .description a {
  color: #ffd1a2;
  text-decoration: none;
}
.node-type-productrange .orange .description a:hover {
  color: #fff;
}
.node-type-productrange .orange .rates table tr:first-of-type td:nth-of-type(2),
.node-type-productrange .orange .rates table tr:first-of-type td:nth-of-type(3) {
  background: #ff8d1a;
}
.node-type-productrange .green .description {
  background: #70a539;
}
.node-type-productrange .green .description h3 {
  color: #d7ffad;
}
.node-type-productrange .green .description a {
  color: #d7ffad;
  text-decoration: none;
}
.node-type-productrange .green .description a:hover {
  color: #fff;
}
.node-type-productrange .green .rates table tr:first-of-type td:nth-of-type(2),
.node-type-productrange .green .rates table tr:first-of-type td:nth-of-type(3) {
  background: #70a539;
}
.node-type-productrange .purple .description {
  background: #6443b5;
}
.node-type-productrange .purple .description h3 {
  color: #cbb8ff;
}
.node-type-productrange .purple .description a {
  color: #cbb8ff;
  text-decoration: none;
}
.node-type-productrange .purple .description a:hover {
  color: #fff;
}
.node-type-productrange .purple .rates table tr:first-of-type td:nth-of-type(2),
.node-type-productrange .purple .rates table tr:first-of-type td:nth-of-type(3) {
  background: #6443b5;
}
.node-type-productrange .submenu,
.node-type-productrange .newtab44 {
  display: inline-block;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  vertical-align: top;
}
.node-type-productrange .submenu li,
.node-type-productrange .newtab44 li {
  list-style: none;
}
.node-type-productrange .newtab44 {
  border-bottom: solid 2px #000;
  top: auto;
  right: auto;
  left: -3px;
  position: relative;
}
.node-type-productrange .description {
  width: 330px;
  float: left;
  color: #fff;
  background-image: url('/images/shadow2.jpg');
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 15px;
  padding-bottom: 35px;
  margin-right: 3px;
}
.node-type-productrange .description p {
  margin-top: 14px;
}
.node-type-productrange .description h3 {
  color: #fff;
  margin-top: 0px;
  font-size: 26px;
}
.node-type-productrange .description h4 {
  font-size: 18px;
  font-family: 'twentytwelve_twentytwelveslab';
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  padding-bottom: 15px;
}
.node-type-productrange .quote {
  display: block;
  margin-top: 15px;
}
.node-type-productrange .photos {
  float: right;
  width: 550px;
  height: auto;
  position: relative;
}
@media screen and (min-width:1024px) {
  .node-type-productrange .photos .thumbs a {
    width: 125px;
    height: 94px;
    display: inline-block;
    margin-right: 10px;
  }
  .node-type-productrange .photos .thumbs a img {
    width: 100%;
    height: auto;
  }
}
.node-type-productrange .photos h2 {
  font-size: 20px;
  font-family: 'twentytwelve_twentytwelveslab';
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  padding-bottom: 15px;
  color: #000;
}
.node-type-productrange .mainphoto {
  width: 540px;
  height: 340px;
  margin-right: 15px;
  border: 5px solid #fff;
  background-color: #ccc;
  -moz-box-shadow: 0 0 3px #888;
  -webkit-box-shadow: 0 0 3px #888;
  box-shadow: 0 0 3px #888;
  margin-bottom: 20px;
  position: relative;
}
.node-type-productrange .mainphoto img {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
}
.node-type-productrange .rates {
  margin-bottom: 20px;
}
.node-type-productrange .rates td {
  padding: 3px;
}
.node-type-productrange .features {
  width: 550px;
  height: 200px;
}
.node-type-productrange .features ul {
  display: block;
  float: left;
  padding-left: 20px;
  list-style: square;
  font-size: 14px;
  line-height: 20px;
  padding-right: 20px;
  margin-right: 40px;
}
.node-type-productrange .features ul:nth-of-type(3) {
  margin-right: 0;
}
.node-type-productrange .spec td {
  padding: 3px;
}
.node-type-productrange .bluerow {
  background-color: #1497C4;
  color: #fff;
}
.node-type-productrange .orangerow {
  background-color: #ff8d1a;
  color: #fff;
}
.node-type-productrange .greenrow {
  background-color: #70a539;
  color: #fff;
}
.node-type-productrange .purplerow {
  background-color: #6241aa;
  color: #fff;
}
.node-type-productrange .greyrow {
  background-color: #dddddd;
  color: #666;
}
.node-type-productrange .spec table {
  margin-left: -8px;
}
.node-type-productrange .spec table tbody tr(odd) {
  background-color: #70a539;
}
.node-type-productrange .spec table tbody tr(even) {
  background-color: #666;
}
@media screen and (max-width:1023px) {
  .node-type-productrange #rangepage {
    width: 100%;
    margin: 0;
  }
  .node-type-productrange .photos {
    width: 100%;
    float: none;
  }
  .node-type-productrange .photos .mainphoto {
    width: 100%;
    height: 50vw;
    left: 8vw;
    position: relative;
    margin: auto;
    text-align: center;
    background: none;
    border: none;
    box-shadow: none;
  }
  .node-type-productrange .photos .thumbs .zoom {
    background-position: right 5px;
    padding: 5px 35px 10px 0;
  }
  .node-type-productrange .photos .thumbs a {
    width: 49%;
    float: left;
  }
  .node-type-productrange .mainphoto {
    text-align: center;
  }
  .node-type-productrange .mainphoto img {
    width: auto;
    max-height: 100%;
    max-width: 100%;
    padding: 3px;
    background: white;
    -webkit-box-shadow: 0 0 0 3px #cccccc;
    -moz-box-shadow: 0 0 0 3px #cccccc;
    box-shadow: 0 0 0 3px #cccccc;
  }
  .node-type-productrange .submenu,
  .node-type-productrange .newtab44 {
    border: none;
    height: auto;
    width: 100%;
    display: block;
  }
  .node-type-productrange .submenu li,
  .node-type-productrange .newtab44 li {
    list-style: none;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 5px 0;
    float: none;
  }
  .node-type-productrange .submenu li a,
  .node-type-productrange .newtab44 li a {
    width: 100%;
  }
  .node-type-productrange .submenu li.active,
  .node-type-productrange .newtab44 li.active {
    background: #a1ed10;
  }
  .node-type-productrange .submenu li.active a,
  .node-type-productrange .newtab44 li.active a {
    color: black;
  }
  .node-type-productrange .submenu {
    margin-bottom: 0;
  }
  .node-type-productrange .newtab44 {
    left: 0;
    margin: 0;
  }
  .node-type-productrange .newtab44 li {
    margin: 0;
  }
  .node-type-productrange .newtab44 li a {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .node-type-productrange .features {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }
  .node-type-productrange .features ul {
    float: none;
  }
  .node-type-productrange .description {
    width: 100%;
    float: none;
  }
  .node-type-productrange .spec {
    width: 100%;
  }
  .node-type-productrange .spec h2 {
    margin-top: 14px;
  }
  .node-type-productrange .spec table {
    margin: 10px auto;
    width: 100%;
    table-layout: fixed;
    font-size: 1em;
    border-collapse: inherit;
    border: none;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .node-type-productrange #rangepage {
    width: 100%;
  }
  .node-type-productrange .photos {
    width: 100%;
    float: none;
  }
  .node-type-productrange .photos .mainphoto {
    width: 70vw;
    left: auto;
    max-height: 40vh;
    text-align: center;
    background: none;
    border: none;
    box-shadow: none;
    margin: auto 15vw;
  }
  .node-type-productrange .photos .mainphoto img {
    height: 100%;
    width: auto;
    max-width: 100%;
    padding: 5px;
    background: white;
    -webkit-box-shadow: 0 0 0 3px #cccccc;
    -moz-box-shadow: 0 0 0 3px #cccccc;
    box-shadow: 0 0 0 3px #cccccc;
  }
  .node-type-productrange .photos .thumbs .zoom {
    background-position: right 10px;
    padding: 10px 35px 10px 0;
  }
  .node-type-productrange .photos .thumbs a {
    width: 33%;
    float: left;
  }
  .node-type-productrange .photos .thumbs a:nth-of-type(7) {
    margin-left: 33%;
  }
  .node-type-productrange .description .quote {
    margin: 5vw 30vw;
  }
  .node-type-productrange .spec h2 {
    margin-top: 14px;
  }
  .node-type-productrange .del-postit {
    padding: 5vw 25vw;
  }
  .node-type-productrange .features ul {
    font-size: 18px;
    line-height: 22px;
  }
  .node-type-productrange .features ul li {
    margin: 5px 0;
  }
}
@media screen and (max-width:719px) {
  .node-type-productrange .quote {
    padding: 0 20vw;
  }
  .node-type-productrange .del-postit {
    padding: 5vw 15vw;
  }
}
.node-type-useful-links .location {
  float: left;
  width: 297px;
  height: auto;
  margin-right: 24px;
  padding-bottom: 25px;
}
.node-type-useful-links .location h4 {
  font-family: 'twentytwelve_twentytwelveslab';
  color: #6342b4;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 7px;
}
.node-type-useful-links .location img {
  margin-bottom: 10px;
}
.node-type-useful-links .location p {
  margin-top: 14px;
}
.node-type-useful-links .location.no-margin {
  margin-right: 0;
}
@media screen and (max-width:1023px) {
  .node-type-useful-links #destination {
    width: 90vw;
    margin: 0 5vw;
  }
  .node-type-useful-links .location {
    float: none;
    display: block;
    width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 25px;
  }
  .node-type-useful-links .location img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .node-type-useful-links .location p {
    margin-top: 14px;
  }
  .node-type-useful-links .location.no-margin {
    margin-right: auto;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .node-type-useful-links .location {
    width: 48%;
    margin: 0 1% 3vw 1%;
    float: left;
    min-height: 36vh;
    padding: 0 2vw;
  }
}
.stage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 900px;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}
.far-clouds {
  background: transparent url('../../img/far-clouds.png') 305px 85px repeat-x;
}
.near-clouds {
  background: transparent url('../../img/near-clouds.png') 305px 162px repeat-x;
}
@media screen and (max-width:1023px) {
  .stage {
    max-width: 100vw;
    min-width: 100vw;
    height: 50vh;
  }
  .far-clouds {
    background: transparent url('../../img/far-clouds.png') 10px 50% repeat-x;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .near-clouds {
    background: transparent url('../../img/near-clouds.png') 10px 50% repeat-x;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
.only-touch {
  display: none;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.left img {
  max-width: 100%;
  height: auto;
}
#overlay-container,
.overlay-modal-background,
.overlay-element {
  height: 100vh !important;
}
@media screen and (max-width:1023px) {
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  .only-touch {
    display: block;
  }
  .only-touch.inline {
    display: inline;
  }
  .getaquote {
    position: fixed;
    left: -35vw;
    top: 0;
    width: 30vw;
    height: 15vh;
    z-index: 1999;
    -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .getaquote.visible {
    left: 3vw;
  }
  .container {
    width: 100vw;
    padding: 0;
  }
  .main {
    padding-bottom: 0;
  }
  .left,
  .right {
    float: none;
    display: block;
    width: 80vw;
    margin: auto 10vw;
  }
  .left .offers,
  .left .extra,
  .left .euro {
    width: 90%;
    margin: auto;
  }
  .left .offers,
  .left .extra {
    background: none;
    text-align: center;
  }
  .left .offers a,
  .left .extra a {
    font-weight: bold;
  }
  .left .euro h4 {
    margin-bottom: 10vw;
  }
  .left .top {
    margin: 0 10vw;
  }
  .left .top h4 {
    width: 100%;
  }
  .right {
    padding: 7px;
  }
  img {
    width: 100%;
    height: auto;
  }
  .mobile-hidden {
    max-height: 0;
    overflow: hidden;
  }
  .mobile-hidden.active {
    max-height: 100vh;
    overflow-y: auto;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  html,
  body {
    font-size: 18px;
    line-height: 22px;
  }
  html a,
  body a {
    font-weight: bold;
  }
  .getaquote {
    position: fixed;
    left: -35vw;
    top: 0;
    width: 20vw;
    height: 15vh;
    z-index: 1999;
    -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .getaquote.visible {
    left: 3vw;
  }
  .left {
    width: 100vw;
    margin: 3vw 0;
  }
  .left .top,
  .left .euro,
  .left .offers,
  .left .extra,
  .left > img {
    width: 44%;
    float: left;
    height: auto;
    margin: 3vw 3%;
  }
  .left .offers,
  .left .extra {
    text-align: center;
    background: none;
  }
}
@media screen and (max-width:719px) and  (orientation : landscape) {
  .left .top span img {
    padding: 0 10vw;
  }
  .left .item-24 {
    padding: 0 10vw;
  }
}
.chevron {
  position: relative;
  display: inline-block;
  height: 7px;
}
.chevron:before,
.chevron:after {
  position: absolute;
  display: block;
  content: "";
  border: 5px solid transparent;
}
.chevron:before {
  top: 0;
  border-top-color: #000;
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .not-mcol {
    display: none;
  }
}
@media screen and (max-width:719px) {
  .not-smcol {
    display: none;
  }
}
@media screen and (max-width:1023px) {
  .not-touch {
    display: none;
  }
}
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.clearfix:after {
  clear: both;
}
/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.clearfix {
  *zoom: 1;
}
/*! fancyBox v2.1.0 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10020;
}
.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.fancybox-opened {
  z-index: 10030;
}
.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url('/fancybox/fancybox_sprite.png');
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 10060;
}
#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('/fancybox/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 10040;
}
.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url('/fancybox/blank.gif');
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 10040;
}
.fancybox-prev {
  left: 0;
}
.fancybox-next {
  right: 0;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 10040;
  visibility: hidden;
}
.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}
.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}
.fancybox-nav:hover span {
  visibility: visible;
}
.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}
/* Overlay helper */
.fancybox-lock {
  overflow: hidden;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 10010;
  background: url('/fancybox/fancybox_overlay.png');
}
.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 10050;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 10050;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}
@media screen and (max-width:1023px) {
  .footer {
    margin: 14px auto 0;
    height: auto;
    padding-top: 0;
    width: 100vw;
    clear: both;
    overflow: hidden;
  }
  .footer .foot {
    width: 100%;
    text-align: center;
  }
  .footer .foot .footlist {
    width: 100%;
    float: left;
    margin: 0;
  }
  .footer .foot .footlist h6 {
    padding: 14px 0;
    color: #00275b;
    border-bottom: 1px solid #00275b;
  }
  .footer .foot .footlist ul li {
    padding: 7px 0;
    font-size: 1.2em;
  }
  .footer .foot .footlist ul li a {
    color: #00275b;
    font-weight: bold;
  }
  .footer .foot .footlist #facebook2,
  .footer .foot .footlist #google2,
  .footer .foot .footlist #twitter2 {
    background: none;
    padding: 0;
    height: auto;
  }
  .footer .foot .footlist2,
  .footer .foot .copyright {
    width: 100%;
    float: none;
    text-align: center;
  }
  .footer .foot .footlist2 span,
  .footer .foot .copyright span {
    text-align: center;
    width: 100%;
    margin: 5px auto;
  }
  .footer .foot .footlist2 {
    padding: 0 5vw;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .footer .foot .footlist h6 {
    font-size: 22px;
    line-height: 28px;
  }
  .footer .foot .footlist ul li {
    padding: 3vw 0;
  }
  .footer .foot .footlist2 {
    padding: 0 25vw;
  }
}
@media screen and (max-width:719px) and  (orientation : landscape) {
  .footer .foot .footlist2 img {
    padding: 0 25vw;
  }
}
@media screen and (max-width:1023px) {
  .header {
    width: 100vw;
    height: auto;
    background: none;
  }
  .header .logo {
    position: relative;
    top: auto;
    left: auto;
    max-width: 65vw;
    height: auto;
    margin: auto;
  }
  .header h1.strapline {
    position: relative;
    margin: auto;
    width: 100vw;
    height: auto;
    top: auto;
    left: auto;
    font-size: 2em;
    text-align: center;
  }
  .header .contacts {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: 100vw;
    height: auto;
    padding: 0;
    background: none;
    margin: 10px auto;
  }
  .header .contacts .tel {
    margin: 15px auto;
    width: 100vw;
    height: auto;
    top: auto;
    left: auto;
    font-size: 1.5em;
    text-align: center;
  }
  .header .contacts .email {
    margin: 10px auto;
    width: 100vw;
    height: auto;
    top: auto;
    left: auto;
    font-size: 1em;
    text-align: center;
  }
  .header .contacts .email a {
    color: white;
  }
  .header .contacts .social {
    display: none;
  }
}
.navbar .nav-toggle {
  display: none;
}
.navbar .nav li {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
.navbar .nav li a.active {
  color: #a1ed10;
}
.navbar .nav li:first-of-type {
  padding-left: 10px;
}
@media screen and (max-width:1023px) {
  .navbar {
    z-index: 1001;
    position: relative;
    width: 100vw;
  }
  .navbar .nav-toggle {
    display: block;
    width: 100%;
    color: white;
    text-align: center;
    line-height: 45px;
  }
  .navbar .nav {
    background: #00275b;
    max-height: 0;
    overflow: hidden;
    width: 100%;
    margin: auto;
    padding: 0;
    -webkit-transition: height 300ms ease;
    -moz-transition: height 300ms ease;
    -ms-transition: height 300ms ease;
    -o-transition: height 300ms ease;
    transition: height 300ms ease;
  }
  .navbar .nav li {
    float: none;
    width: 100%;
    display: block;
    padding: 0;
    margin-top: 5px;
  }
  .navbar .nav li a {
    line-height: 100%;
    display: block;
    white-space: normal;
  }
  .navbar .nav li a.active {
    color: #a1ed10;
  }
  .navbar .nav li:first-of-type {
    padding-left: 0px;
  }
  .navbar .nav.open {
    max-height: 90vh;
    overflow-y: scroll;
    padding: 0 0 14px;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .navbar {
    font-size: 17px;
    line-height: 23px;
  }
  .navbar .nav li {
    padding: 10px 0;
  }
}
.webform-client-form .field {
  width: 96%;
  padding: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.webform-client-form .form-textarea,
.webform-client-form .form-select {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.webform-client-form .required {
  color: #e42128;
}
.webform-client-form .info,
.webform-client-form .success,
.webform-client-form .warning,
.webform-client-form .error,
.webform-client-form .validation {
  border: 1px solid;
  margin: 10px 0px;
  padding: 15px 10px 15px 50px;
  background-repeat: no-repeat;
  background-position: 10px 15px;
}
.webform-client-form .success {
  color: #4F8A10;
  background-color: #DFF2BF;
  background-image: url('/images/success.png');
}
.webform-client-form .error {
  color: #D8000C;
  background-color: #FFBABA;
  background-image: url('/images/Error.png');
}
.webform-client-form #city {
  display: none;
}
.webform-client-form .form-actions .webform-submit {
  height: 53px;
  width: 109px;
  background: transparent;
  color: white;
  border: none;
  font-size: 0;
  background: url('/images/submit.jpg') no-repeat;
  cursor: pointer;
}
@media screen and (max-width:1023px) {
  .webform-client-form .field {
    width: 100%;
  }
  #form {
    width: 80vw;
    margin: 2vw 10vw;
  }
  #form h4 {
    width: 100%;
  }
}
@media screen and (min-width:720px) and (max-width:1023px) {
  .webform-client-form .field {
    padding: 10px 5px;
    font-size: 22px;
    line-height: 22px;
  }
  #form {
    font-size: 21px;
    line-height: 28px;
  }
}
